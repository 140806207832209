import React from "react"
import Input from "./../../../../../components/forms/Input"
import CheckboxCard from "./../../../../../components/forms/CheckboxCard"

export class AnlageForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      check1: false,
      check12: false,
    }

    this.toggleCheck1 = this.toggleCheck1.bind(this)
    this.toggleCheck2 = this.toggleCheck2.bind(this)
  }

  toggleCheck1(e) {
    let input = document.getElementById("monatlich").checked
    if (input === true) {
      this.setState({
        check1: true,
      })
    } else {
      this.setState({
        check1: false,
      })
    }
  }

  toggleCheck2(e) {
    let input = document.getElementById("einmalanlage").checked
    if (input === true) {
      this.setState({
        check2: true,
      })
    } else {
      this.setState({
        check2: false,
      })
    }
  }

  render() {
    const svg = {
      monatlich: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="inline"
          width={64}
          height={64}
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="#000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3" />
          <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3-3l3-3" />
        </svg>
      ),
      einmalig: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="inline"
          width={64}
          height={64}
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="#000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
          <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
        </svg>
      ),
      feordrung: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="inline"
          width={64}
          height={64}
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="#000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1={3} y1={21} x2={21} y2={21} />
          <line x1={3} y1={10} x2={21} y2={10} />
          <polyline points="5 6 12 3 19 6" />
          <line x1={4} y1={10} x2={4} y2={21} />
          <line x1={20} y1={10} x2={20} y2={21} />
          <line x1={8} y1={14} x2={8} y2={17} />
          <line x1={12} y1={14} x2={12} y2={17} />
          <line x1={16} y1={14} x2={16} y2={17} />
        </svg>
      ),
      flex: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="inline"
          width={64}
          height={64}
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="#000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <circle cx={6} cy={10} r={2} />
          <line x1={6} y1={4} x2={6} y2={8} />
          <line x1={6} y1={12} x2={6} y2={20} />
          <circle cx={12} cy={16} r={2} />
          <line x1={12} y1={4} x2={12} y2={14} />
          <line x1={12} y1={18} x2={12} y2={20} />
          <circle cx={18} cy={7} r={2} />
          <line x1={18} y1={4} x2={18} y2={5} />
          <line x1={18} y1={9} x2={18} y2={20} />
        </svg>
      ),
    }

    let check1
    if (this.state.check1 === true) {
      check1 = (
        <Input
          type="number"
          pattern="[0-9]+([\.,][0-9]+)?"
          label="Höhe des Sparbeitrags in €"
          id="sparbeitrag-monatlich"
          name="Höhe des Sparbeitrags"
          placeholder="100,00"
        />
      )
    }

    let check2
    if (this.state.check2 === true) {
      check2 = (
        <Input
          type="number"
          pattern="[0-9]+([\.,][0-9]+)?"
          label="Höhe der Einmalanlage in €"
          id="hoehe-einmalanlage"
          name="Höhe der Einmalanlage"
          placeholder="10.000,00"
        />
      )
    }

    return (
      <React.Fragment>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 mb-6">
          <div>
            <div
              onKeyDown={this.toggleCheck1}
              onClick={this.toggleCheck1}
              tabIndex={0}
              role="button"
            >
              <CheckboxCard
                label="Monatlicher Sparbeitrag"
                name="Monatlicher Sparbeitrag"
                value="Ja"
                id="monatlich"
                svg={svg.monatlich}
              />
            </div>
            <div className="mt-4">{check1}</div>
          </div>
          <div>
            <div
              onKeyDown={this.toggleCheck1}
              onClick={this.toggleCheck2}
              tabIndex={0}
              role="button"
            >
              <CheckboxCard
                label="Einmalanlage"
                name="Einmalanlage"
                value="Ja"
                id="einmalanlage"
                svg={svg.einmalig}
              />
            </div>
            <div className="mt-4">{check2}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export class AnlageFoerderung extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const svg = {
      job: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="inline"
          width={64}
          height={64}
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="#000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <rect x={3} y={7} width={18} height={13} rx={2} />
          <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
          <line x1={12} y1={12} x2={12} y2="12.01" />
          <path d="M3 13a20 20 0 0 0 18 0" />
        </svg>
      ),
      kind: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="inline"
          width={64}
          height={64}
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="#000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <circle cx={12} cy={12} r={9} />
          <line x1={9} y1={10} x2="9.01" y2={10} />
          <line x1={15} y1={10} x2="15.01" y2={10} />
          <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
          <path d="M12 3a2 2 0 0 0 0 4" />
        </svg>
      ),
    }

    return (
      <React.Fragment>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 mb-6">
          <div>
            <CheckboxCard
              label="Vermögenswirksame Leistungen durch den Arbeitgeber"
              name="Vermögenswirksame Leistungen durch den Arbeitgeber"
              value="Ja"
              id="foerderungen-arbeitgeber"
              svg={svg.job}
            />
          </div>
          <div>
            <CheckboxCard
              label="Sparen für Kinder"
              name="Sparen für Kinder"
              value="Ja"
              id="sparen-kinder"
              svg={svg.kind}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
